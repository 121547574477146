<template>
  <form @submit.prevent class="formulario-container" id="cadastro-campanha">
    <div class="formulario-linha linha-tripla">
      <div class="formulario-item">
        <label class="formulario-item-label">Selecione a campanha</label>
        <multiselect label="titulo" track-by="titulo" v-model="form.campanha" :options="campanhasSelect"
          :searchable="false" :allow-empty="false" placeholder="" select-label="Selecionar" deselect-label="Remover"
          selected-label="Selecionado" @input="buscarPremios($event)" />
      </div>
    </div>

    <div class="formulario-item linha-tripla">
      <label class="formulario-item-label">Descritivo do Prêmio</label>
      <input type="text" v-model="form.descricaoPremio" maxlength="100" class="text-area" />
    </div>

    <div class="premios">
      <div>
        <div class="premio">
          <div class="formulario-linha linha-tripla">
            <div class="formulario-item medio">
              <label class="formulario-item-label">Qual a posição</label>
              <multiselect v-model="form.ordenacao" label="id" track-by="id" :options="ordenacao" :searchable="false"
                placeholder="Selecione" select-label="Selecionar" deselect-label=" " selected-label="Selecionado"
                :allow-empty="false" />
            </div>

            <div class="formulario-item medio">
              <label class="formulario-item-label">Qual tipo de prêmio</label>
              <multiselect v-model="form.tipoPremio" label="nome" track-by="nome" :options="tipoPremio"
                :searchable="false" placeholder="Selecione" select-label="Selecionar" deselect-label=" "
                selected-label="Selecionado" :allow-empty="false" />
            </div>

            <!-- <div class="formulario-item medio" v-if="editar">
              <label class="formulario-item-label">Ativo</label>
              <multiselect
                v-model="form.ativo"
                label="nome"
                track-by="nome"
                :options="ativo"
                :searchable="false"
                placeholder="Selecione"
                select-label="Selecionar"
                deselect-label=" "
                selected-label="Selecionado"
                :allow-empty="false"
              />
            </div> -->
          </div>

          <div class="formulario-linha linha-tripla" v-if="form.tipoPremio.id == 1">
            <div class="formulario-item medio">
              <label class="formulario-item-label">Qual o número de pontos</label>
              <input type="number" v-model="form.pontos" class="text-area" />
            </div>
          </div>
          <div class="formulario-item" v-if="form.tipoPremio.id == 2">
            <label class="formulario-item-label">Qual o produto? (Descreva em até 20 caracteres)</label>
            <input type="text" v-model="form.produtos" maxlength="20" class="text-area" />
          </div>
        </div>

        <div class="formulario-linha linha-dupla" v-if="form.ordenacao.id == 1">
          <label class="label-titulo-banner">Imagem principal do Prêmio</label>

          <div class="formulario-item container-upload">
            <input id="input-imagem-premio" type="file" accept=".png, .jpeg, .jpg"
              @change="atualizarImagem('imagemPremio', 'imagemPremio', $event)" />
            <div :style="`background: url(${imagemPremio}) center no-repeat; width: 375px; height: 250px;`" :class="
              imagemPremio
                ? 'bg-upload-div upload-div active'
                : 'bg-upload-div upload-div'
            ">
              <span v-if="imagemPremio == null">365px x 230px</span>
            </div>
            <div>
              <label for="input-imagem-premio" class="upload-btn">Adicionar Imagem</label>
              <transition name="fade">
                <a v-if="imagemPremio" class="remove-image" @click="
                  removerImagem(
                    'imagemPremio',
                    'imagemPremio',
                    'input-imagem-premio'
                  )
                ">
                  Remover
                </a>
              </transition>
            </div>
          </div>
        </div>
      </div>

      <div class="container-btns">
        <button class="btn btn-laranja" @click="salvar" v-if="listaPremios.length < 10">
          Salvar
        </button>
      </div>
    </div>

    <h2 class="titulo-lista-premios">Lista de Prêmios</h2>
    <div class="lista-premios">
      <div class="tabela-informacoes">
        <div class="tabela-linha tabela-linha-titulo">
          <div class="tabela-linha-nome">
            <p>Posição</p>
          </div>
          <div class="tabela-linha-nome">
            <p>Prêmio</p>
          </div>
          <div class="tabela-linha-nome">
            <p>Produto/Pontos</p>
          </div>
          <div class="tabela-linha-nome">
            <p>Status</p>
          </div>
        </div>

        <div class="tabela-linha tabela-linha-informacoes" v-for="(item, index) in listaPremios" :key="index">
          <div class="tabela-linha-nome">
            <p>{{ item.posicao }}</p>
          </div>
          <div class="tabela-linha-nome">
            <p>{{ item.premio }}</p>
          </div>
          <div class="tabela-linha-nome">
            <p>
              {{ item.pontosOuProdutos }}
            </p>
          </div>
          <div class="tabela-linha-nome tabela-linha-btns">
            <p>{{ item.ativo ? "Ativo" : "Inativo" }}</p>
            <div v-if="item.ativo && item.posicao != 1" class="tabela-linha-btns-editar" @click="alteraStatus(item)">
              <img src="@/assets/images/icones/inativar.svg" alt="Inativar" title="Inativar" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="container-btns">
      <button class="btn btn-cancelar" @click.prevent="$router.push('/cadastro-premios/listar')">
        Voltar
      </button>
    </div>
  </form>
</template>

<script>
import {
  cadastrarPremios,
  tituloCampanhas,
  atualizaPremio,
  buscaPremioPorCampanha,
  getPremioPorId,
} from "@/services/campanhas";
import AwesomeMask from "awesome-mask";
import Multiselect from "vue-multiselect";

export default {
  name: "FormularioBannerTreinamento",
  props: ["editar"],
  components: {
    Multiselect,
  },
  directives: {
    mask: AwesomeMask,
  },
  data() {
    return {
      // ativo: [
      //   { id: true, nome: "SIM" },
      //   { id: false, nome: "NÃO" },
      // ],
      listaPremios: [],
      campanhasSelect: [],
      tipoPremio: [
        { id: 1, nome: "Pontos" },
        { id: 2, nome: "Produtos" },
      ],
      ordenacao: [
        { id: 1 },
        { id: 2, $isDisabled: true },
        { id: 3, $isDisabled: true },
        { id: 4, $isDisabled: true },
        { id: 5, $isDisabled: true },
      ],
      tipo: [],
      dadosEditar: {},
      form: {
        campanha: [],
        descricaoPremio: "",
        ordenacao: [],
        tipoPremio: [],
        pontos: 0,
        produtos: "",
        imagemPremio: "",
      },
      imagemPremio: null,
    };
  },
  mounted() {
    this.form.ordenacao = this.ordenacao.find((item) => item.id == 1);
    this.form.tipoPremio = this.tipoPremio.find((item) => item.id == 1);
    this.listaTitulosCampanhas();
  },
  methods: {
    alteraStatus(premio) {
      const data = new FormData();
      data.append("Campanha", premio.campanha);
      data.append("Premio", premio.premio);
      data.append("Posicao", premio.posicao);
      data.append("TipoPremio", premio.tipoPremio);
      data.append("Pontos", premio.pontos);
      data.append("Produtos", premio.produtos);

      data.append("Id", Number(premio.id));

      if (typeof this.form.imagemPremio == "string") {
        var premioBinary = new File(
          [this.b64toBlob(this.form.imagemPremio)],
          "temp.jpeg",
          { type: "image/jpeg" }
        );

        data.append("ImagemPremioDesktop", premioBinary);
        data.append("ImagemPremioMobile", premioBinary);
      } else {
        data.append("ImagemPremioDesktop", this.form.imagemPremio);
        data.append("ImagemPremioMobile", this.form.imagemPremio);
      }

      if (premio.ativo) {
        data.append("Ativo", 0);
      } else {
        data.append("Ativo", 1);
      }

      atualizaPremio(data).then((resp) => {
        this.buscarPremios();

        this.$store.commit("SET_MODAL", {
          ativado: true,
          mensagem: resp.data.mensagem,
          tipo: "sucesso",
        });
      });
    },
    buscarPremios(event) {
      let idCampanha;
      if (event) {
        idCampanha = event.id;
      } else {
        idCampanha = this.form.campanha.id;
      }
      buscaPremioPorCampanha(idCampanha).then((resp) => {
        if (resp.data.lista.length > 0) {
          this.listaPremios = resp.data.lista;
        } else {
          this.listaPremios = [];
        }
        this.listaPremios.map((r) =>
          r.produtos == "0" && r.produtos
            ? (r.pontosOuProdutos = r.pontos)
            : (r.pontosOuProdutos = r.produtos)
        );
        if (this.listaPremios.length > 0) {
          this.ordenacao.map((item) => {
            item.$isDisabled = false;
          });
        } else {
          this.ordenacao.map((item) => {
            if (item.id != 1) {
              item.$isDisabled = true;
            }
          });

          this.form.ordenacao = this.ordenacao.find((item) => item.id == 1);
        }
      });
    },
    removerImagem(form, imagem, elem) {
      this[imagem] = null;
      this.form[form] = null;
      document.querySelector(`#${elem}`).value = null;
    },
    atualizarImagem(form, imagem, $event) {
      if (!$event.target.files[0]) {
        this[imagem] = null;
        this.form[form] = null;
        return false;
      }
      this.form[form] = $event.target.files[0];
      const reader = new FileReader();
      reader.onload = (bg) => {
        this[imagem] = bg.target.result;
      };
      reader.readAsDataURL($event.target.files[0]);
    },
    listaTitulosCampanhas() {
      tituloCampanhas().then((resp) => {
        this.campanhasSelect = resp.data;
        if (this.editar) {
          let idCampanha = this.$route.query.idCampanha;
          let idPremio = this.$route.params.id;
          this.form.campanha = this.campanhasSelect.find(
            (item) => item.id == idCampanha
          );
          this.buscarPremios();
          this.carregaDadosPremio(idPremio);
        }
      });
    },
    carregaDadosPremio(idPremio) {
      getPremioPorId(idPremio).then((resp) => {
        if (resp.data.result) {
          this.form.descricaoPremio = resp.data.result.premio;

          this.form.ordenacao = this.ordenacao.find(
            (item) => item.id == resp.data.result.posicao
          );

          this.form.tipoPremio = this.tipoPremio.find(
            (item) => item.id == resp.data.result.tipoPremio
          );

          this.form.pontos = resp.data.result.pontos;
          this.form.produtos = resp.data.result.produtos;

          this.imagemPremio =
            "data:image/png;base64," + resp.data.result.imagemPremioDesktop;
          this.form.imagemPremio = resp.data.result.imagemPremioDesktop;
        }
      });
    },
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      return blob;
    },
    salvar() {
      if (!this.form.campanha.id) {
        this.$store.commit("SET_MODAL", {
          ativado: true,
          mensagem: "Selecione uma campanha",
          tipo: "erro",
        });
        return false;
      }

      if (!this.form.descricaoPremio) {
        this.$store.commit("SET_MODAL", {
          ativado: true,
          mensagem: "Informe a descrição do prêmio",
          tipo: "erro",
        });
        return false;
      }

      const data = new FormData();
      data.append("Campanha", this.form.campanha.id);
      data.append("Premio", this.form.descricaoPremio);
      data.append("Posicao", this.form.ordenacao.id);
      data.append("TipoPremio", this.form.tipoPremio.id);
      data.append("Pontos", this.form.pontos);
      data.append("Produtos", this.form.produtos);

      if (this.editar) {
        data.append("Ativo", 1);
        data.append("Id", Number(this.$route.params.id));

        if (typeof this.form.imagemPremio == "string") {
          var premioBinary = new File(
            [this.b64toBlob(this.form.imagemPremio)],
            "temp.jpeg",
            { type: "image/jpeg" }
          );

          data.append("ImagemPremioDesktop", premioBinary);
          data.append("ImagemPremioMobile", premioBinary);
        } else {
          data.append("ImagemPremioDesktop", this.form.imagemPremio);
          data.append("ImagemPremioMobile", this.form.imagemPremio);
        }

        atualizaPremio(data).then((resp) => {
          this.buscarPremios();
          this.$store.commit("SET_MODAL", {
            ativado: true,
            mensagem: resp.data.mensagem,
            tipo: "sucesso",
          });
        });
      } else {
        data.append("ImagemPremioDesktop", this.form.imagemPremio);
        data.append("ImagemPremioMobile", this.form.imagemPremio);

        cadastrarPremios(data).then((resp) => {
          if (resp.data.sucesso) {
            this.$store.commit("SET_MODAL", {
              ativado: true,
              mensagem: resp.data.mensagem,
              tipo: "sucesso",
            });

            this.form.descricaoPremio = "";
            this.form.ordenacao = this.ordenacao.find((item) => item.id == 1);
            this.form.tipoPremio = this.tipoPremio.find((item) => item.id == 1);
            this.form.pontos = 0;
            this.form.produtos = "";

            this.buscarPremios();
          }
        });
      }
    },
  },
};
</script>

<style src="vue-multiselect/dist/vue-multiselect.min.css"/>

<style lang="scss">
#descritivo-premio,
#descritivo {
  height: 300px;
  border-radius: 0 0 5px 5px;
}

.btn-ver-campanha {
  display: grid;
  align-items: center;
  padding-top: 15px;
  font-size: 16px;
  height: 100%;
}

.btn-excluir {
  font-size: 14px;
  text-decoration: none;
  cursor: pointer;
}

#cadastro-campanha {
  .titulo-lista-premios {
    font-size: 28px;
    width: 100%;
    margin: 15px;
  }

  .lista-premios {
    max-height: 300px;
    overflow-x: auto;
  }

  .premios {
    margin: 20px 0;
    padding: 20px 0;
  }

  .premio {
    margin-bottom: 50px;
  }

  .label-titulo-banner {
    font-size: 24px;
    width: 100%;
    margin: 10px;
  }
}
</style>
